/*
 * @Author: 张天鹏
 * @Date: 2019-12-11 11:39:19
 * @LastEditors: 张天鹏
 * @LastEditTime: 2019-12-11 11:39:21
 */
import axios from '@/lib/api.request'
// 订单详情列表
export const orderformlist = data => axios.post('/cpk/abtComCpkOrderList/list', data)
// 删除订单
export const orderformdelete = params => axios.delete('/cpk/abtComCpkOrderList/derorder', { params })
