<!--
 * @Author: 刘格优
 * @Date: 2020-04-09 16:13:02
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-10 14:22:38
 -->
<template>
  <div>
    <div class="riqi">
      <div @click='prev'
           class="riqileft">
        <span :v-model="prevs">
          <md-icon name="arrow-left"></md-icon>{{prevs}}
        </span>月
      </div>
      <div>
        <span :v-model="year">{{year}}</span>年
        <span :v-model="now">{{now}}</span>月
      </div>
      <div @click='next'
           class="riqiright">
        <span :v-model="nexts">{{nexts}}</span>月<md-icon name="arrow-right"></md-icon>
      </div>
    </div>
    <div class="list"
         v-if="reportList.length"
         style="height:92vh;overflow-y:auto">
      <md-scroll-view class="scroll"
                      ref="scrollView"
                      auto-reflow
                      :scrolling-x="false"
                      @end-reached="loadMorechange">
        <div class="product"
             v-for="(item,index) of reportList"
             :key="index">
          <div @click="skip(item)">
            <div class="producttop">
              <div class="producttopl">
                <p class="omit">{{item.prodname}}</p>
              </div>
              <div class="producttopr"
                   v-if="item.ddstatus=='0'">
                <p><span>待支付</span><span class="marginleft"><img src="@/assets/cpk/images/time.png"
                         alt=""></span></p>
              </div>
              <div class="producttopr"
                   v-if="item.ddstatus=='1'">
                <p><span class="succe">已完成</span><span class="marginleft"><img src="@/assets/cpk/images/dui.png"
                         alt=""></span></p>
              </div>
            </div>
            <div class="productcen">
              <div class="productcon">
                <div class="productconl">
                  <p>投保人</p>
                </div>
                <div class="productconr">
                  <p>{{item.apidname}}</p>
                </div>
              </div>
              <div class="productcon">
                <div class="productconl">
                  <p>被保人</p>
                </div>
                <div class="productconr">
                  <p>{{item.pidname}}</p>
                </div>
              </div>
              <div class="productcon">
                <div class="productconl">
                  <p>投保时间</p>
                </div>
                <div class="productconr">
                  <p>{{item.appdate}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="productbom">
            <div class="productboml">
              <span class="wfont">保费<span class="hfont">{{item.mount}}</span>元</span>
            </div>
            <!-- <div class="producttg"
                 v-if="item.ddstatus=='1'">
              <span class="wfont">推广费<span class="hfont">{{item.costvalue ? item.costvalue : '0.00'}}</span>元</span>
            </div>
            <div class="productbomr"
                 v-if="item.ddstatus=='1' &&item.yhjmount">
              <span class="wfont">奖金<span class="hfont">{{item.yhjmount ? item.yhjmount : '0.00'}}</span>元</span>
            </div> -->
            <div class="productbomr"
                 v-if="item.ddstatus=='1' &&item.tjmount">
              <span class="wfont">推荐费<span class="hfont">{{item.tjmount ? item.tjmount : '0.00'}}</span>元</span>
            </div>
          </div>
        </div>
        <md-scroll-view-more slot="more"
                             :is-finished="listFinished" />
      </md-scroll-view>
    </div>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import loadMorechange from '@/mixins/loadMorechange'
import { orderformlist } from '@/api/cpk/orderform/index'
export default {
  mixins: [loadMorechange],
  data () {
    return {
      prevs: 10,
      year: 2019,
      now: 11,
      nexts: 12,
      listpageNo: 1,
      size: 6,
      total: 0,
      listtotalPage: 0,
      loading: false,
      isNull: false,
      loadflag: false,
      listFinished: false,
      reportList: [],
      empno: ''
    };
  },

  created () {
    this.empno = this.$route.query.empno ? this.$route.query.empno : ''
    var d = new Date()
    var month = (d.getMonth() + 1)
    var year = d.getFullYear()
    this.year = year
    this.now = month
    if (month == '1') {
      this.prevs = 12
      this.nexts = (month - 0) + 1
    } else if (month == '12') {
      this.prevs = (month - 0) - 1
      this.nexts = 1
    } else {
      this.prevs = (month - 0) - 1
      this.nexts = (month - 0) + 1
    }
    this.getData()
  },
  methods: {
    prev () {
      if (this.prevs == 1) {
        this.prevs = 12
        this.year = this.year
        this.now = 1
        this.nexts = this.now + 1
      } else if (this.prevs == 12) {
        this.prevs = this.prevs - 1
        this.year = this.year - 1
        this.now = 12
        this.nexts = 1
      } else {
        if (this.nexts == 1) {
          this.prevs = this.prevs - 1
          this.year = this.year
          this.now = this.now - 1
          this.nexts = 12
        } else {
          this.prevs = this.prevs - 1
          this.year = this.year
          this.now = this.now - 1
          this.nexts = this.nexts - 1
        }
      }
      this.listpageNo = 1
      this.reportList = []
      this.listFinished = false
      this.loadflag = false
      if (this.reportList.length !== 0) {
        this.$refs.scrollView.finishLoadMore()
      }
      this.getData()
    },
    next () {
      // console.log(this.nexts)
      if (this.nexts == 12) {
        this.prevs = this.prevs + 1
        this.year = this.year
        this.now = 12
        this.nexts = 1
      } else if (this.nexts == 1) {
        this.prevs = 12
        this.year = this.year + 1
        this.now = 1
        this.nexts = this.nexts + 1
      } else {
        if (this.prevs == 12) {
          this.prevs = 1
          this.year = this.year
          this.now = this.now + 1
          this.nexts = this.nexts + 1
        } else {
          this.prevs = this.prevs + 1
          this.year = this.year
          this.now = this.now + 1
          this.nexts = this.nexts + 1
        }
      }
      this.listpageNo = 1
      this.reportList = []
      this.listFinished = false
      this.loadflag = false
      if (this.reportList.length !== 0) {
        this.$refs.scrollView.finishLoadMore()
      }
      this.getData()
    },
    getData () {
      let yearmon = this.year + '-' + this.now
      let data = {
        page: this.listpageNo,
        size: this.size,
        ddstatus: '1',
        data: yearmon,
        empno: this.empno
      }
      orderformlist(data).then(res => {
        this.reportList =
          this.reportList == []
            ? res.data.data.rows
            : this.reportList.concat(res.data.data.rows)
        this.total = res.data.data.total
        this.listtotalPage = res.data.data.totalpage
        if (this.reportList.length > 0) {
          this.isNull = false
        } else {
          this.isNull = true
        }
        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          if (this.reportList.length !== 0) {
            this.$nextTick(() => {
              this.$refs.scrollView.finishLoadMore()
            })
          }
        }
        this.loadflag = true
      })
    }
  }
}

</script>
<style scoped lang="stylus">
@import './order.styl';
</style>